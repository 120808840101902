<template>
  <page-container>
    <div class="user-info-container">
      <div class="right-bg">

      </div>
      <van-image
          class="user-avatar"
          :src="userInfo.avatar || ''"
          round
      >
        <template v-slot:error>
          <van-image style="width: 100%;height: 100%" src="yzkp_logo.jpg" round/>
        </template>
      </van-image>
      <div class="user-name">
        <span>{{ userInfo.nickname }}</span>
        <div
            class="sign-in-btn"
            @click="$router.push('/signIn')"
        >
          <van-image fit="fit" src="img/my/sign-in-icon.svg"/>
          签到
        </div>
      </div>
    </div>
    <div class="mission-status-list">
      <div
          class="status-item"
          v-for="{ id, name, icon } in MISSION_STATUS_LIST"
          :key="id"
          @click="handleToMission(id)"
      >
        <van-image fit="contain" :src="icon"/>
        <span>{{ name + '任务' }}</span>
      </div>
    </div>

    <van-cell-group class="router-container">
      <van-cell title="我的课程" icon="video-o" is-link @click="() => {
        $router.push('/home/course');
        $store.commit('homeCourse/setRefreshing', true);
      }">
        <template #icon>
          <van-image class="route-icon-img" fit="contain" src="img/my/course.svg"/>
        </template>
      </van-cell>
      <van-cell title="我的佣金" icon="balance-o" is-link @click="$router.push('/commission')">
        <template #icon>
          <van-image class="route-icon-img" fit="contain" src="img/my/commission.svg"/>
        </template>
      </van-cell>
      <van-cell title="我的合同" icon="description" is-link @click="$router.push('/agreementList')">
        <template #icon>
          <van-image class="route-icon-img" fit="contain" src="img/my/agreement.svg"/>
        </template>
      </van-cell>
<!--      <van-cell title="我的下级" icon="friends-o" is-link @click="$router.push('/subordinate')">-->
<!--        <template #icon>-->
<!--          <van-image class="route-icon-img" fit="contain" src="img/my/lower.svg"/>-->
<!--        </template>-->
<!--      </van-cell>-->
      <van-cell title="关于系统" icon="smile-o" is-link @click="$router.push('/about')">
        <template #icon>
          <van-image class="route-icon-img" fit="contain" src="img/my/about.svg"/>
        </template>
      </van-cell>
    </van-cell-group>
  </page-container>
</template>

<script>
import { getUserInfo } from '@/api/user';
import { MISSION_STATUS, MISSION_STATUS_MAP } from '@/views/mission/const';

export default {
  name: 'my',
  data() {
    return {
      userInfo: {},
    };
  },
  computed: {
    MISSION_STATUS_LIST() {
      let { JIN_XING_ZHONG, YI_WAN_CHENG } = MISSION_STATUS;
      return [
        { id: JIN_XING_ZHONG, name: MISSION_STATUS_MAP[JIN_XING_ZHONG], icon: 'img/my/mission_status_2.svg' },
        { id: YI_WAN_CHENG, name: MISSION_STATUS_MAP[YI_WAN_CHENG], icon: 'img/my/mission_status_1.svg' },
      ];
    },
  },
  created() {
    getUserInfo().then(res => {
      this.userInfo = res || {};
    });
  },
  methods: {
    handleToMission(status) {
      this.$router.push({
        path: '/home/mission',
        query: {
          status,
        },
      });

      this.$store.commit('homeMission/setRefreshing', true);
    },
  },
};
</script>

<style lang="less" scoped>
.user-info-container {
  padding: 19px 0 0 16px;
  //background: -webkit-linear-gradient(to bottom, @primary-color 0%, #fff 61.8%);
  //background: linear-gradient(to bottom, @primary-color 0%, #fff 61.8%);

  position: relative;
  background-color: #65d27e;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 10vw;
    left: -62vw;
    width: 188vw;
    height: 200vw;
    background-color: #fff;
    border-radius: 100%;
  }

  .right-bg {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    background-color: #fff;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      top: -185.9vw;
      left: -74vw;
      width: 188vw;
      height: 200vw;
      border-radius: 100%;
      background-color: #65d27e;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: -182.9vw;
      left: -64vw;
      width: 192vw;
      height: 200vw;
      border-radius: 100%;
      background-color: #79db97;
    }
  }

  .user-avatar {
    position: relative;
    z-index: 9;
    margin: 0 0 0 10px;
    width: 54px;
    height: 54px;
    border: 3px solid #fff;
  }

  .user-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 9;
    font-size: 16px;
    color: #212121;

    & > span {
      display: inline-block;
      padding: 0 16px 0 0;
      min-width: 80px;
      text-align: center;
    }

    .sign-in-btn {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      width: 88px;
      height: 29px;
      letter-spacing: 2px;
      font-size: 14px;
      color: white;
      border-radius: 14px 0 0 14px;
      background: -webkit-linear-gradient(to top, #46D670, #9de483);
      background: linear-gradient(to top, #46D670, #9de483);

      .van-image {
        margin: 0 7px 0 20px;
        width: 18px;
        height: 17px;
      }

      &:active {
        background: -webkit-linear-gradient(to top, #9de483, #b8e2a9);
        background: linear-gradient(to top, #9de483, #b8e2a9);
      }
    }
  }
}

.mission-status-list {
  display: flex;
  justify-content: space-evenly;
  margin: 0 0 13px 0;
  padding: 20px 0;
  background-color: #fff;

  .status-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > .van-image {
      margin: 0 0 12px 0;
      width: 32px;
      height: 32px;
    }

    & > span {
      font-size: 14px;
      font-weight: 500;
      color: #666;
    }
  }
}

.router-container {
  .van-cell {
    padding: 16px;
    font-size: 17px;
    align-items: center;

    &:after {
      right: 0;
    }

    .route-icon-img {
      margin-right: 14px;
      width: 20.5px;
      height: 20px;
    }
  }
}
</style>
