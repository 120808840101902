var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c('div',{staticClass:"user-info-container"},[_c('div',{staticClass:"right-bg"}),_c('van-image',{staticClass:"user-avatar",attrs:{"src":_vm.userInfo.avatar || '',"round":""},scopedSlots:_vm._u([{key:"error",fn:function(){return [_c('van-image',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"yzkp_logo.jpg","round":""}})]},proxy:true}])}),_c('div',{staticClass:"user-name"},[_c('span',[_vm._v(_vm._s(_vm.userInfo.nickname))]),_c('div',{staticClass:"sign-in-btn",on:{"click":function($event){return _vm.$router.push('/signIn')}}},[_c('van-image',{attrs:{"fit":"fit","src":"img/my/sign-in-icon.svg"}}),_vm._v(" 签到 ")],1)])],1),_c('div',{staticClass:"mission-status-list"},_vm._l((_vm.MISSION_STATUS_LIST),function(ref){
var id = ref.id;
var name = ref.name;
var icon = ref.icon;
return _c('div',{key:id,staticClass:"status-item",on:{"click":function($event){return _vm.handleToMission(id)}}},[_c('van-image',{attrs:{"fit":"contain","src":icon}}),_c('span',[_vm._v(_vm._s(name + '任务'))])],1)}),0),_c('van-cell-group',{staticClass:"router-container"},[_c('van-cell',{attrs:{"title":"我的课程","icon":"video-o","is-link":""},on:{"click":function () {
        _vm.$router.push('/home/course');
        _vm.$store.commit('homeCourse/setRefreshing', true);
      }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('van-image',{staticClass:"route-icon-img",attrs:{"fit":"contain","src":"img/my/course.svg"}})]},proxy:true}])}),_c('van-cell',{attrs:{"title":"我的佣金","icon":"balance-o","is-link":""},on:{"click":function($event){return _vm.$router.push('/commission')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('van-image',{staticClass:"route-icon-img",attrs:{"fit":"contain","src":"img/my/commission.svg"}})]},proxy:true}])}),_c('van-cell',{attrs:{"title":"我的合同","icon":"description","is-link":""},on:{"click":function($event){return _vm.$router.push('/agreementList')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('van-image',{staticClass:"route-icon-img",attrs:{"fit":"contain","src":"img/my/agreement.svg"}})]},proxy:true}])}),_c('van-cell',{attrs:{"title":"关于系统","icon":"smile-o","is-link":""},on:{"click":function($event){return _vm.$router.push('/about')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('van-image',{staticClass:"route-icon-img",attrs:{"fit":"contain","src":"img/my/about.svg"}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }